<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">Yaplay</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Forgot password V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1"> Ative a sua conta 🔒 </b-card-title>
          <b-card-text class="mb-2">
            Insira o código 'key' recebido no seu email
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group label="Código" label-for="forgot-password-email">
                <validation-provider
                  #default="{ errors }"
                  name="Código"
                  rules="required"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="codigo"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-email"
                    placeholder="Insira aqui seu código"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button type="submit" @click="submit()" variant="primary" block>
                Solicitar Ativação
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'auth-login' }">
              <feather-icon icon="ChevronLeftIcon" /> Voltar para o Login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import usersService from '@/services/usersService'
import accountService from '@/services/accountService'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      codigo: null,
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    submit() {},
    activeAccount(key) {
      accountService
        .activateAccount(key)
        .then(res => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Conta ativada com sucesso! Realize seu login',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'login' })
        })
        .catch(e => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Não foi possível ativar sua conta',
              icon: 'TimesIcon',
              variant: 'warn',
            },
          })
        })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          accountService
            .resetPasswordInit({ email: this.email })
            .then(res => {
              console.log(res)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    'Email de recuperação enviado com sucesso! Cheque seu email.',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$router.push({ name: 'login' })
            })
            .catch(e => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Não foi possível realizar sua recuperação',
                  icon: 'TimesIcon',
                  variant: 'warn',
                },
              })
            })
        }
      })
    },
  },
  mounted() {
    const key = this.$route.query.key
    if (key) {
      this.activeAccount(key)
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
